import * as React from 'react';
import { CircularProgress } from '@mui/material';
import { DivStack } from './styles';

const Loading: React.FC = () => {
  return (
    <DivStack>
      <CircularProgress color="inherit" />
    </DivStack>
  );
};

export default Loading;
