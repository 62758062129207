export const columns: any[] = [
  {
    name: 'videoId',
    title: 'ID',
  },
  {
    name: 'originalname',
    title: 'Name',
  },
  {
    name: 'cdn',
    title: 'URL',
  },
  // {
  //   name: 'video',
  //   title: 'Videos',
  //   header: '',
  //   isColumnFilteringEnabled: false,
  //   getCellValue: (row: any) => {
  //     const iconProperties = (
  //       <>
  //         <img src={`${row.server}/${row.filename}`} />
  //       </>
  //     );

  //     return iconProperties;
  //   },
  // },
  {
    name: 'copyUrl',
    title: 'Copy Url',
  },
  { name: 'delete', title: '' },
];
