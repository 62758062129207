/* eslint-disable no-param-reassign */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import { Container } from './styles';
import FormTreino from '../Form';
import BackButton from '../../../components/BackButton';

// interface Treino {
//   alunoId: number;
//   nome: string;
//   descricao: string;
//   ativo: number;
//   tipo: string;
//   dataInicio: Date;
//   dataFim: Date;
//   dataVencimento: Date;
//   seriesFicha: string;
//   repeticoesFicha: string;
//   intervaloFicha: string;
//   carga: string;
//   observacao: string;
//   imagemTreino: string;
//   exercicios: Exercicio[];
//   created_by: string;
//   created_at: Date;
//   updated_by: string;
//   updated_at: Date;
// }

// interface Exercicio {
//   exercicioId: number;
//   nome: string;
//   categoria: string;
//   grupoMuscular: string;
//   usaEquipamento: boolean;
//   equipameo: string;
//   created_by: string;
//   createdat: Date;
//   updated_by: string;
//   updated_at: Date;
// }

interface ParamTypes {
  id: string;
}

const CreateTreino: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { addToast } = useToast();
  const [dataInicioFromDb, setDataInicioFromDb] = useState('');
  const [dateFimFromDb, setDataFimFromDb] = useState('');

  const [exerciciosAdicionadosParaSalvar, setExerciciosAdicionadosParaSalvar] =
    useState<any>([]);

  useEffect(() => {
    let dateTest = moment.utc(new Date()).format('YYYY-MM-DD');
    setDataInicioFromDb(dateTest);
    dateTest = moment.utc(new Date()).format('YYYY-MM-DD');
    setDataFimFromDb(dateTest);
  }, []);

  const updateExercicios = useCallback((exericios: any[]): void => {
    setExerciciosAdicionadosParaSalvar(exericios);
  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        delete data.carga;
        delete data.series;
        delete data.repeticoes;
        delete data.intervalo;
        console.log(`data para salvar`, data);

        const novoTreino = {
          ...data,
          alunoId: id,
          ativo: data.ativo === 1,
          observacao: data.observacao,
          exercicios: exerciciosAdicionadosParaSalvar,
        };

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome do treino é obrigatório'),
        });

        await schema.validate(novoTreino, {
          abortEarly: false,
        });

        const response = await api.post('/treinos', novoTreino);

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Treino cadastrado com sucesso!',
        });
        history.push(`/alunos/alunopanel/${response.data.alunoId}`);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: `Registration Error`,
          description:
            'Ocorreu um erro ao criar o treino, por favor tente novamente.',
        });
      }
    },
    [addToast, exerciciosAdicionadosParaSalvar, history, id],
  );

  return (
    <Container>
      <h1>Novo treino</h1>
      <BackButton text="Voltar" url={`/alunos/alunopanel/${id}`} />
      <FormTreino
        formRef={formRef}
        handleSubmit={handleSubmit}
        idAluno={id}
        editEnabled={true}
        buttonText="Create"
        dataInicioFromDb={dataInicioFromDb}
        dataFimFromDb={dateFimFromDb}
        setDataInicioFromDb={setDataInicioFromDb}
        setDataFimFromDb={setDataFimFromDb}
        updateExercicios={updateExercicios}
      ></FormTreino>
    </Container>
  );
};

export default CreateTreino;
