/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { randomBytes } from 'crypto';
import { FiCopy, FiVideo } from 'react-icons/fi';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../../services/api';
import Table from '../../components/DevExpressTable/DevExpressTable';
import { IActionColumn } from '../../components/DevExpressTable/ActionsColumn';
import { columns } from './columns';
import {
  Container,
  Content,
  ButtonUpload,
  DeleteIcon,
  DivStack,
} from './styles';
import { useToast } from '../../hooks/toast';
import Loading from '../../components/Loading';

interface Video {
  videoId: string;
  filename: string;
  name: string;
  url: string;
  videoType: string;
  isActive: boolean;
  server: string;
}

interface State {
  select: boolean;
  surveyId: number;
  videoId?: string;
  survey: any;
}

// interface VideoObj {
//   id: string;
//   filePath: string;
// }

const Videos: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const [videos, setVideos] = useState<Video[]>([]);
  const [fileServerUrl, setFileServerUrl] = useState('');
  const { state } = useLocation<State>();
  const [selectedRowIds, setSelectedRowIds] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const [tableColumnExtensions] = useState([
    { columnFilteringEnabled: false },
    { columnName: 'videoId', width: 120 },
    { columnName: 'originalname', width: 800 },
    { columnName: 'video', width: 310, filteringEnabled: false },
    { columnName: 'copyUrl', width: 120, filteringEnabled: false },
    { columnName: 'delete', width: 80 },
  ]);

  const handleClickUrlCopy = useCallback((row: any) => {
    navigator.clipboard.writeText(`${row.cdn}`);
  }, []);

  const handleClickDelete = useCallback(
    (row: any) => {
      try {
        setLoading(true);
        // eslint-disable-next-line no-restricted-globals, no-alert
        const userWantDelete = confirm(
          `Are you sure you want to delete the video ${row.videoId}?`,
        );

        if (userWantDelete) {
          api.delete(`/videos/${row.videoId}`).then(response => {
            if (response.data?.deleted === true) {
              if (row.videoId !== undefined) {
                row?.api?.updateRows([
                  { videoId: row.videoId, _action: 'delete' },
                ]);
              }

              history.push('/projects');
              history.push('/videos');
            } else {
              addToast({
                type: 'info',
                title: 'Video is being used',
                description: response.data?.message,
              });
            }
          });
        }
      } catch (err) {
        if (err) {
          console.log(err);
          addToast({
            type: 'error',
            title: 'Updating Error',
            description: `An error occurred while deleting this video, please try again.`,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, history],
  );

  const [actionColumns] = useState<IActionColumn[]>([
    {
      columnName: 'copyUrl',
      label: 'Copy',
      onClick: handleClickUrlCopy,
      icon: <FiCopy />,
    },
    {
      columnName: 'delete',
      label: 'Delete',
      onClick: handleClickDelete,
      icon: <DeleteIcon />,
    },
  ]);

  useEffect(() => {
    if (fileServerUrl) {
      const hack = '';
    }

    api.get('/videos').then(response => {
      setVideos(
        response.data.map((video: Video) => {
          return {
            ...video,
            videoId: video.videoId.toString(),
            server: response.request?.responseURL.replace('/videos', '/files'),
          };
        }),
      );

      if (state?.videoId) {
        setSelectedRowIds([state?.videoId]);
      }
    });
  }, [fileServerUrl, state?.videoId]);

  const handleVideoChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      setFileServerUrl(randomBytes?.toString());
      let urlString = '';

      if (e.target.files) {
        const data = new FormData();

        data.append('video', e.target.files[0]);
        setUploading(true);

        await api
          .post(`/videos`, data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            urlString = response.request?.responseURL.replace(
              '/videos',
              '/files',
            );
            setFileServerUrl(urlString);

            addToast({
              type: 'success',
              title: 'Success',
              description: 'The video was uploaded successfully!',
            });
          })
          .catch(error => {
            addToast({
              type: 'error',
              title: 'Upload Error',
              description:
                'An error occurred while uploading the video, please try again.',
            });
          })
          .finally(() => {
            setUploading(false);
          });
      }
    },
    [addToast],
  );

  return (
    <Container>
      <h1>Videos </h1>
      {loading === true ? (
        <Loading />
      ) : (
        <div>
          <ButtonUpload aria-disabled={!!uploading}>
            <div>
              <label htmlFor="video">
                Upload New Video <FiVideo />
                <input
                  type="file"
                  id="video"
                  accept="video/*"
                  onChange={handleVideoChange}
                />
              </label>
            </div>
          </ButtonUpload>
        </div>
      )}
      <Content>
        {uploading === true ? (
          <DivStack>
            <CircularProgress size={32} style={{ color: 'gray' }} />
          </DivStack>
        ) : (
          <Table
            columnsProp={columns}
            dataProp={videos}
            selectionProp={selectedRowIds}
            multiSelection={false}
            setSelectedRowId={setSelectedRowIds}
            checkboxSelection={false}
            tableColumnExtensions={tableColumnExtensions}
            actionColumns={actionColumns}
            hasFilterRow={true}
            idName={'videoId'}
          ></Table>
        )}
      </Content>
    </Container>
  );
};

export default Videos;
