import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from './styles';
import Tabs from './Tabs';
import Aluno from './Tabs/Aluno';

interface ParamTypes {
  id: string;
  tabId: string;
}

const AlunoFicha: React.FC = () => {
  const { id, tabId } = useParams<ParamTypes>();

  // useEffect(() => {
  //   setTabIdState(state.tabId);
  //   console.log(`idAluno Tabs`, id);
  // }, [id, state.tabId]);
  return (
    <Container>
      <Tabs idAluno={Number(id)} tabId={Number(tabId)}>
        <Aluno />
      </Tabs>
    </Container>
  );
};

export default AlunoFicha;
