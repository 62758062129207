/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useParams } from 'react-router-dom';
import api from '../../../../services/api';

import { useToast } from '../../../../hooks/toast';
import getValidationErrors from '../../../../utils/getValidationErrors';

import Input from '../../../../components/FormInput';
import Select from '../../../../components/SelectForm';
import Button from '../../../../components/Button';

import { Container } from './styles';

interface Aluno {
  nome: string;
  telefone: string;
  endereco: string;
  dataNascimento: Date;
  email: string;
  treinoEmCasa: SelectType;
  nomeAcademia: number;
}

interface SelectType {
  value: number;
  label: string;
}

interface ParamTypes {
  id: string;
}

const treinoEmCasaOptions: SelectType[] = [
  { value: 0, label: 'Sim' },
  { value: 1, label: 'Não' },
];

const CreateAluno: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { id } = useParams<ParamTypes>();

  const { addToast } = useToast();

  const [selectedTreinoEmCasa, setSelectedTreinoEmCasa] = useState<SelectType>({
    value: 1,
    label: 'Não',
  });

  const [aluno, setAluno] = useState<Aluno>();
  const [loading, setLoading] = useState(false);
  //  const [countries, setCountries] = useState<SelectProps[]>();

  // useEffect(() => {
  //   api.get('customerCountries').then(response => {
  //     setCountries(response.data);
  //   });
  // }, []);

  // const handleCountryChange = useCallback(countryList => {
  //   setSelectedCountries(countryList);
  // }, []);
  const handleTreinoEmCasaChange = useCallback(value => {
    setSelectedTreinoEmCasa(value);
  }, []);

  useEffect(() => {
    let mounted = true;

    setLoading(true);
    console.log(`alunoId`, id);
    api.get(`alunos/${id}`).then(response => {
      if (response.data) {
        if (mounted) {
          setAluno(response.data);
        }
      }
    });
    setLoading(false);

    return () => {
      mounted = false;
    };
  }, [id]);

  useEffect(() => {
    // const loadData = async (): Promise<void> => {
    //   // let mounted = true;
    //   // if (mounted) {
    //   // await api.post(`/questions/listTags`).then(response => {
    //   //   setTagsSearch(response.data);
    //   // });

    //   await api.get(`/alunos/${id}`).then(response => {
    //     if (response.data) {
    //       setAluno(response.data);

    //       formRef.current?.setFieldValue('nome', response.data.nome);
    //       formRef.current?.setFieldValue('telefone', response.data.telefone);
    //       formRef.current?.setFieldValue('email', response.data.email);
    //       formRef.current?.setFieldValue('endereco', response.data.endereco);
    //       formRef.current?.setFieldValue(
    //         'nomeAcademia',
    //         response.data.nomeAcademia,
    //       );
    //       formRef.current?.setFieldValue(
    //         'dataNascimento',
    //         response.data.dataNascimento,
    //       );

    //       formRef.current?.setFieldValue('treinoEmCasa', {
    //         value: response.data.treinoEmCasa,
    //         label: response.data.treinoEmCasa ? 'Sim' : 'Não',
    //       });
    //     }
    //   });
    //   // }
    //   // return () => {
    //   //   mounted = false;
    //   // };
    // };
    // loadData();

    let mounted = true;

    api.get(`/alunos/${id}`).then(response => {
      if (response.data) {
        if (mounted) {
          setAluno(response.data);

          formRef.current?.setFieldValue('nome', response.data.nome);
          formRef.current?.setFieldValue('telefone', response.data.telefone);
          formRef.current?.setFieldValue('email', response.data.email);
          formRef.current?.setFieldValue('endereco', response.data.endereco);
          formRef.current?.setFieldValue(
            'nomeAcademia',
            response.data.nomeAcademia,
          );
          formRef.current?.setFieldValue(
            'dataNascimento',
            response.data.dataNascimento,
          );

          formRef.current?.setFieldValue('treinoEmCasa', {
            value: response.data.treinoEmCasa,
            label: response.data.treinoEmCasa ? 'Sim' : 'Não',
          });
        }
      }
    });

    return () => {
      mounted = false;
    };
  }, [id]);

  const handleSubmit = useCallback(
    async (data: Aluno) => {
      try {
        const novoAluno = {
          ...data,
          treinoEmCasa: selectedTreinoEmCasa.value === 1,
          // employee: selectedEmployeeNumber,
          // sector: selectedSector,
          // industry: selectedIndustry,
          // countries: selectedCountries,
          // asset: selectedAsset,
          // revenue: selectedRevenue,
        };

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome do aluno é obrigatório'),
        });

        await schema.validate(novoAluno, {
          abortEarly: false,
        });

        const response = await api.post('/alunos', novoAluno);
        if (response) {
          // history.push('/alunos');
        }

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Aluno cadastrado com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: `Registration Error ${aluno?.nome}`,
          description:
            'An error occurred while creating customer, please try again.',
        });
      }
    },
    [addToast, aluno?.nome, selectedTreinoEmCasa.value],
  );

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <h1>Aluno</h1>

        <h3>Nome</h3>
        <Input
          type="text"
          name="nome"
          placeholder="Digite o nome completo do aluno"
        />

        <h3>Telefone</h3>
        <Input type="text" name="telefone" placeholder="(32) 9 0000-0000" />

        <h3>Email</h3>
        <Input type="text" name="email" placeholder="Ex.: aluno@forfit.com" />

        <h3>Endereço</h3>
        <Input
          type="text"
          name="endereco"
          placeholder="Digite o endereço do aluno"
        />

        <h3>Data Nascimento</h3>
        <Input type="date" name="dataNascimento" placeholder="Ex: 01/01/1900" />

        <h3>Treino em casa?</h3>
        <Select
          options={treinoEmCasaOptions}
          value={selectedTreinoEmCasa}
          onChange={e => handleTreinoEmCasaChange(e)}
          name="treinoEmCasa"
          placeholder="Sim ou Não"
        />

        <h3>Nome da Academia</h3>
        <Input
          type="text"
          name="nomeAcademia"
          placeholder="Digite o nome da academia onde o aluno treina"
        />

        <Button type="submit" width="200px">
          Salvar
        </Button>
      </Form>
    </Container>
  );
};

export default CreateAluno;
